@import "../../../styles/antd.less";


.new-customers-wrapper {
    height: 232px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__title {
            color: @white;
        }

        &__numbers {
            font-size: 28px;
            text-align: right;
            line-height: 1.2;

            div {
                font-size: 20px;
            }

            span {
                color: @white;
            }

            img {
                margin-left: 5px;
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .new-customers-wrapper {
        height: 212px;

        .header {
            &__numbers {
                font-size: 30px;

                div {
                    font-size: 20px;
                }
            }
        }
    }
}
